
import { defineComponent } from 'vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import getConfig from '@/plugins/config';

const GRID_URL = getConfig('VUE_APP_GRIDS_BASE_URL');
export default defineComponent({
  name: 'Grid Page',

  components: { DashboardLayout },
  data() {
    debugger;
    return {
      url: `${GRID_URL}/exponent`,
      hostname: window.location.origin,
    };
  },
  mounted() {
    const input = document.getElementById('click') as HTMLInputElement | null;
    if (input != null) {
      input.click();
    }
  },
  methods: {
    submit() {
      const form = document.getElementById('_gotoTeg') as HTMLFormElement | null;

      if (form != null) {
      //  form.setAttribute('action', `${GRID_URL}/exponent`);
        form.submit();
      }
    },
  },
  created() {
    debugger;
  },
});
